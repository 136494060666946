import { createContext, PropsWithChildren, useContext } from 'react';
import TripcoLogo from '@/Components/SVG/Brand/TripcoLogo';
import LekkeSlaapLogo from '@/Components/SVG/Brand/LekkeSlaapLogo';
import siteMeta, { SiteMetadata } from '@/lib/Domain/Meta/site_meta';
import { SiteKey } from '@/types';

const SITE_MAP: Record<SiteKey, Omit<ThemeContextType, 'buildUrl'>> = {
    tc: {
        theme: 'theme-tc',
        themeAlt: 'theme-tc-dark',
        logo: <TripcoLogo className="h-10 w-40 md:h-16 md:w-48" />,
        font: 'font-sans',
        terms: 'https://www.lekkeslaap.co.za/terms',
        meta: siteMeta.tc,
    },
    ls: {
        theme: 'theme-ls',
        themeAlt: 'theme-ls',
        logo: <LekkeSlaapLogo className="h-10 w-40 md:h-16 md:w-48" />,
        font: 'font-montserrat',
        terms: 'https://www.lekkeslaap.co.za/terms',
        meta: siteMeta.ls,
    }
};

export type ThemeContextType = {
    theme: string,
    themeAlt: string,
    logo: React.ReactNode,
    font: string,
    terms: string,
    meta: SiteMetadata,
    buildUrl: (path: string) => string
};

const ThemeContext = createContext<ThemeContextType>({
    ...SITE_MAP.ls,
    buildUrl: (path: string) => {
        const url = new URL(path, SITE_MAP.ls.meta.url);
        return url.toString();
    }
});

export function useTheme() {
    const value = useContext(ThemeContext);
    if (!value) {
        throw new Error('useTheme must be used within a ThemeProvider context');
    }
    return value;
}


type Props = {
    site?: SiteKey
};

export default function ThemeProvider({ site = 'ls', children }: PropsWithChildren<Props>) {

    const theme = SITE_MAP[site];

    const providerValue: ThemeContextType = {
        ...theme,
        buildUrl: (path: string) => {
            const url = new URL(path, theme.meta.url);
            return url.toString();
        }
    }

    return <ThemeContext.Provider value={providerValue}>
        {children}
    </ThemeContext.Provider>
}
