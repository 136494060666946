import './bootstrap'
import '../css/app.css'
import '/node_modules/@fortawesome/fontawesome-svg-core/styles.css';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { config } from '@fortawesome/fontawesome-svg-core';
import { initializeSentry } from './lib/Domain/Infrastructure/Monitoring/sentry';
import { preventErrorPageUrlRewrites } from './lib/Domain/Infrastructure/ErrorHandling/preventErrorPageUrlRewrites';
import AppProvider from './Provider/AppProvider';
import { SiteKey } from './types';
import { IS_DEV_MODE } from '@/lib/constants'

config.autoAddCss = false;

initializeSentry();

preventErrorPageUrlRewrites();

createInertiaApp({
    title: title => (title.includes('|') || title.includes('-')) ? title : `${title} - Lavender`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const site = props.initialPage.props.site as SiteKey ?? undefined;
        if (IS_DEV_MODE) {
            // Using client-side rendering in development
            const root = createRoot(el);
            root.render(<AppProvider site={site}><App {...props} /></AppProvider>);
        } else {
            // Using server-side rendering in production
            hydrateRoot(el, <AppProvider site={site}><App {...props} /></AppProvider>);
        }
    }
})

