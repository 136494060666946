import { cn } from '@/lib/utils/shadcn';

type Props = {
    className?: string;
}

export default function TripcoLogo({ className }: Props) {
    return <svg
        className={cn('h-4 w-auto', className)}
        role="img"
        aria-label="Tripco Logo"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        clipRule="evenodd"
        viewBox="0 0 236 72"
    >
        <path fill="#d65d24" d="M67.357 0.128H82.468V13.701H67.357z"></path>
        <path className="fill-foreground" d="M67.357 17.176H82.468V62.458H67.357z"></path>
        <path
            className="fill-foreground"
            fillRule="nonzero"
            d="M33.51 37.052v25.266h14.442v-23.76a8.364 8.364 0 018.364-8.364h4.497V17.177h-7.428c-10.977 0-19.875 8.898-19.875 19.875M14.204 1.876L.028 11.757V42.02c0 11.334 9.188 20.523 20.522 20.523h5.831V49.002h-3.684c-4.254 0-8.493-3.448-8.493-7.702V28.453h12.118l.059-7.121v-5.395H14.204V1.876zM112.701 49.075a9.236 9.236 0 01-9.237-9.237 9.238 9.238 0 119.237 9.237m0-32.499c-12.847 0-23.261 10.415-23.261 23.262v32.033h14.024V61.182a23.163 23.163 0 009.237 1.917c12.847 0 23.261-10.415 23.261-23.261 0-12.847-10.414-23.262-23.261-23.262M212.379 49.075a9.236 9.236 0 01-9.237-9.237 9.238 9.238 0 119.237 9.237m23.215-10.729c-.718-11.515-10.04-20.909-21.55-21.711a23.183 23.183 0 00-17.688 6.35c-.519.493-1.03.994-1.567 1.467l-9.282 8.175-.001-.006-15.784 13.924c-.173.164-.35.322-.535.472l-.025.022-.002-.001a9.228 9.228 0 01-1.289.877l-.002.001a9.176 9.176 0 01-4.501 1.159c-4.92-.013-9.086-4.083-9.209-9.001a9.238 9.238 0 019.235-9.474c3.646 0 6.789 2.118 8.292 5.186l10.56-9.566c-4.726-6.532-12.756-10.515-21.642-9.48-10.624 1.236-19.151 9.818-20.325 20.449-1.549 14.033 9.395 25.91 23.115 25.91 6.242 0 11.906-2.464 16.084-6.466l10.811-9.51c3.058 9.277 11.789 15.976 22.09 15.976 13.34 0 24.058-11.23 23.215-24.753"
        ></path>
    </svg>
}
