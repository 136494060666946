import { PropsWithChildren } from 'react';
import ImageCDNProvider from './ImageCDNProvider';
import ThemeProvider from './ThemeProvider';
import type { SiteKey } from '@/types';

export default function AppProvider({ site = 'ls', children }: PropsWithChildren<{ site?: SiteKey }>) {
    return (
        <ThemeProvider site={site}>
            <ImageCDNProvider>
                {children}
            </ImageCDNProvider>
        </ThemeProvider>
    )
}
