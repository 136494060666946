import { PropsWithChildren } from 'react';
import { ImgixProvider } from 'react-imgix';
import { IMGIX_DOMAINS } from '@/lib/constants';


export default function ImageCDNProvider({ children }: PropsWithChildren<{}>) {

    return <ImgixProvider
        domain={IMGIX_DOMAINS.TRAVELGROUND}
        imgixParams={{
            auto: 'enhance,format,compress',
            fit: 'crop',
            q: 80,
        }}>
        {children}
    </ImgixProvider>
}
