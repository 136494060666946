import { router } from '@inertiajs/react';
import * as Sentry from '@sentry/react'

export function initializeSentry() {
    // Initialize Sentry

    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_ENV,
        integrations: [
            Sentry.browserTracingIntegration({
                // disable auto span creation
                instrumentNavigation: false,
                instrumentPageLoad: false,
            }),
            Sentry.replayIntegration(),
        ],
        tracePropagationTargets: ['localhost', 'tripco.co.za'],
        tracesSampleRate: 1.0,          // 100% of transactions
        replaysSessionSampleRate: 0.1,  // 10% of sessions
        replaysOnErrorSampleRate: 1.0,  // 100% of errors
    })

    // Browser Tracing Setup

    let client = Sentry.getClient();

    let pageLoadSpan = Sentry.startBrowserTracingPageLoadSpan(client!, {
        name: `${route().current()}`,
        attributes: {
            [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'url',
        },
    });

    router.on('before', (e) => {
        if (pageLoadSpan) {
            pageLoadSpan.updateName(`${route().current()}`);
            pageLoadSpan.setAttribute(
                Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE,
                'route',
            );
            pageLoadSpan = undefined;
        } else {
            Sentry.startBrowserTracingNavigationSpan(client!, {
                op: 'navigation',
                name: `${route().current()}`,
                attributes: {
                    [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route',
                },
            })
        }
    })
}
