import { router } from '@inertiajs/react';

export function preventErrorPageUrlRewrites() {
    router.on('navigate', (e) => {
        // Only intercept errors
        if (e.detail.page.component.startsWith('Error/')) {
            const referrer = e.detail.page.props.referrer as string;
            if (referrer && referrer !== 'Unknown') {
                window.history.replaceState({}, '', (new URL(referrer)).pathname);
            }
        }
    });
}
