export const IS_DEV_MODE = process.env.NODE_ENV === 'development' || false;

export const LS_BASE_URL = new URL('https://lekkeslaap.co.za');

export const STAFF_IMG_URL = 'https://tripco-static.imgix.net/staff/'

export const IMGIX_DOMAINS = {
    TVS_BLOG: 'tvs-blog.imgix.net',
    TVG_NEWSLETTER: 'tvg-newsletter.imgix.net',
    TRAVELGROUND: 'travelground.imgix.net',
    TRIPCO_SOCIAL: 'tripco-social.imgix.net',
    TRIPCO_STATIC: 'tripco-static.imgix.net',
}

export const FULL_WIDTH_SRCSET_OPTS = {
    widths: [
        440,    // Mobile
        768,    // md
        1024,   // lg
        1536,   // 2xl
        1920,   // HD
        3840,   // 4K
    ],
};
